import { getCustomerConfig } from './hooks/useCustomerConfig'

const TENANT_ID =
  process.env.REACT_APP_ENTRA_TENANT_ID ||
  'd2d34757-bda2-4a7a-849c-74e8d372b73d'
// const API_APP_ID = process.env.REACT_APP_ENTRA_API_APP_ID || '8c444b48-a7e4-45fa-9ef9-c071d2f5923d'

export const apiSearchScopeAction =
  'https://semanticlab.onmicrosoft.com/semantic-pulse-api/search' // `api://${API_APP_ID}/search`

const customerConfig = getCustomerConfig()

export const msalConfig = {
  auth: {
    clientId: customerConfig.entraClientId,
    authority: `https://login.microsoftonline.com/${TENANT_ID}/v2.0/.well-known/openid-configuration`,
    redirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email', apiSearchScopeAction],
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}
